@charset "UTF-8";

/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*,
::after,
::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
     :root {
        scroll-behavior: smooth
    }
}

/* style tag from API start  */

.configurator-row {
    padding: 70px 110px 0!important;
}

.configurator-container {
    height: 100vh;
    position: relative;
}

.btn-group.btn-group-sm {
    position: absolute;
    top: 0;
    right: 20px!important;
    z-index: 200000!important;
}

#house-svg {
    position: absolute;
    z-index: 2;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    mix-blend-mode: multiply;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0
}

.mask-img {
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center center;
    -webkit-mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.house-pic {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.images {
    position: relative
}


/* style tag from API end  */

h2.accordion-header {
    position: absolute;
    right: 0px;
    z-index: 1000;
}
.accordion{
    min-height: 614px;
    overflow: hidden;
}
#headingRoof {
    top: 200px;
}

#headingGutters {
    top: 290px;
}

#headingWalls {
    top: 380px;
}

#headingWallsSecond {
    top: 470px;
}

#headingWindows {
    top: 560px;
}

#headingDoors {
    top: 650px;
}

.accordion-button {
    border: 0!important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    /* color: #212529; */
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:focus,
.accordion-button:active,
.accordion-button:focus:active {
    -webkit-appearance: none!important;
    box-shadow: none!important;
    outline: none!important;
}

.accordion-button.collapsed {
    border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #212529;
    transition: background .3s ease;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../assets/svg/download.svg");
    transform: rotate(180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    /* content: ""; */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    /* border-color: #86b7fe; */
    outline: 0;
    /* box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) */
}

.accordion-header {
    margin-bottom: 0
}
#headingDoors,#headingWindows,#headingWallsSecond,#headingWalls,#headingGutters,#headingRoof,.accordion-button{
    width: 100px;
    height: 80px;
    background: #fff;
    border-radius: 10px 0 0 10px!important;
}
.accordion-item:first-of-type .accordion-button {
    background: transparent!important;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem

}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 1px;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 1px;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    
}

.accordion-collapse {
    border: solid rgba(0, 0, 0, .125);
    border-width: 0 1px;
    min-height: 614px;
    overflow: hidden;
}

.accordion-body {
    border: 0px!important;
    padding: 1rem 1.25rem;
}

.accordion-collapse {
    border: 0px !important;
}

.accordion-item {
    border: 0px!important;
}

.accordion-button::after {
    display: none;
}

.accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}


/* new color picker  */

.Polaris-Labelled__LabelWrapper {
    display: none;
}

.Polaris-Connected__Item.Polaris-Connected__Item--connection {
    display: none;
}

.Polaris-TextField__Input {
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 0;
    border-color: transparent;
    box-shadow: 0px 5px 10px rgba(0 0 0 / 50%);
}

.Polaris-TextField__Input:focus,
.Polaris-TextField__Input:active,
.Polaris-TextField__Input:focus:active {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
}

@media screen and(min-width: 1500px) and (max-width: 1960) {
    h2.accordion-header {
        right: -30px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    h2.accordion-header {
        max-width: 100px;
    }
    #headingRoof {
        /* top: 200px; */
        top: 510px;
        left: 17%;
    }
    
    #headingGutters {
        /* top: 290px; */
        top: 510px;
        left: 27%;
    }
    
    #headingWalls {
        /* top: 380px; */
        top: 510px;
        left: 37%;
    }
    
    #headingWallsSecond {
        /* top: 470px; */
        top: 510px;
        left: 47%;
    }
    
    #headingWindows {
        /* top: 560px; */
        top: 510px;
        left: 57%;
    }
    
    #headingDoors {
        /* top: 650px; */
        top: 510px;
        left: 67%;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .configurator-container {
        margin-bottom: 250px;
    }
    h2.accordion-header {
        max-width: 100px;
    }
    #headingRoof {
        /* top: 200px; */
        top: 500px;
        left: 17%;
    }
    
    #headingGutters {
        /* top: 290px; */
        top: 500px;
        left: 27%;
    }
    
    #headingWalls {
        /* top: 380px; */
        top: 500px;
        left: 37%;
    }
    
    #headingWallsSecond {
        /* top: 470px; */
        top: 500px;
        left: 47%;
    }
    
    #headingWindows {
        /* top: 560px; */
        top: 500px;
        left: 57%;
    }
    
    #headingDoors {
        /* top: 650px; */
        top: 500px;
        left: 67%;
    }
}

@media(max-width: 575px) {
    .configurator-container {
        margin-bottom: 20px;
    }
    h2.accordion-header {
        
    }
    #headingRoof {
        top: 350px;
        left:  calc(5% + 0px);
    }
    #headingDoors, #headingWindows, #headingWallsSecond, #headingWalls, #headingGutters, #headingRoof, .accordion-button{
        width: 50px;
        height: 50px;
        border-radius: 10px!important;
        padding: 1px;
    }
    #headingGutters {
        top: 350px;
        left: calc(5% + 55px);
    }
    
    #headingWalls {
        top: 350px;
        left: calc(5% + 110px);
    }
    
    #headingWallsSecond {
        top: 350px;
        left: calc(5% + 165px);
    }
    
    #headingWindows {
        top: 350px;
        left: calc(5% + 220px);
    }
    
    #headingDoors {
        top: 350px;
        left: calc(5% + 275px);
    }
    .accordion-item h2 button img {
        max-height: 30px!important;
    }
    #fullScreen .row.row-without-top {
        padding-bottom: 10px!important;
    }
}