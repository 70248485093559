/* Header-Menu Main Start  */
body {
    width: 100%;
    height: 100%; 
}

.top {
    background: rgba(33, 33, 33, 0.8);
    border-bottom: 2px solid #ff9900;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
}

.brand {
    color: #ff9900;
    font-size: 30px;
    display: inline-block;
    width: 203px;
    margin: 0 10px;
    position: relative;
    -webkit-transform: skewy(-3deg);
            transform: skewy(-3deg);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transition: 200ms all;
}

.brand a.navbar-brand {
    text-transform: uppercase;
    font-family: fantasy;
    font-weight: 800;
    display: block;
    color: #ff9900;
    background: #fff;
    padding: 2px 10px;
    font-size: 35px;
    text-align: center;
    text-decoration: none;
    position: relative;
    z-index: 1;
    text-shadow: 1px 1px 0px #fff, 2px 2px 0px #999, 3px 3px 0px #fff;
    background-image: -webkit-linear-gradient( top, transparent 0%, rgba(0, 0, 0, 0.05) 100%);
    transition: 1s all;
    background-image: -webkit-linear-gradient( left top, transparent 0%, transparent 25%, rgba(0, 0, 0, 0.15) 25%, rgba(0, 0, 0, 0.15) 50%, transparent 50%, transparent 75%, rgba(0, 0, 0, 0.15) 75%);
    background-size: 4px 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4) inset, 0 0 20px -5px rgba(0, 0, 0, 0.4), 0 0 0px 3px #fff inset;
    margin-right: 0px;
}

.brand:hover {
    width: 250px;
    margin: 0 -5px;
}

.brand a.navbar-brand:hover {
    color: #ff9900;
}

.brand:after,
.brand:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 100%;
    background: #bbb;
    -webkit-transform: skewY(8deg);
            transform: skewY(8deg);
    border-radius: 4px;
}

.brand:after {
    right: 0;
    top: -4px;
    background-image: -webkit-linear-gradient( left, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
}

.brand:before {
    left: 0;
    bottom: -4px;
    background-image: -webkit-linear-gradient( right, transparent 0%, rgba(0, 0, 0, 0.4) 100%);
}

nav .collapse ul li:hover a {
    color: #ff9900 !important;
}

nav .collapse ul li.active:hover a {
    color: #fff !important;
}

nav .collapse ul li:hover .dropdown-menu li.dropdown-item a {
    text-decoration: none;
    color: #333 !important;
}

nav .collapse ul li:hover .dropdown-menu li:hover {
    background: #ff9900 !important;
}

nav .collapse ul li:hover .dropdown-menu li:hover a {
    color: #fff !important;
}

.navbar-nav.w-100 {
    padding-top: 20px;
}

/* Header-Menu Main End  */

/* ---------------------------------------------------------------------------- */

/* Footer-Copyright Main Start  */

footer .footerbg {
    height: 150px;
    background: #ff9900;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
}

footer .footerbg .container .row .col-lg-6 p,
footer .footerbg .container .row .col-lg-3 p {
    font-size: 20px;
    font-weight: 400;
}

.copyright {
    height: 100px;
    margin-top: -5vw;
    background-image: linear-gradient(135deg, #777, #111);
}

.copyright .row .col-lg-4 p.text-lg-left a:hover {
    text-decoration: none;
}

footer {
    box-shadow: -1px -5px 15px 0px rgba(255, 146, 26, 0.3);
}

.copyright {
    height: 100px;
    margin-top: -5vw;
    background-image: linear-gradient(135deg, #777, #111);
}

.copyright .row .col-lg-4 p.text-lg-left a:hover {
    text-decoration: none;
}


/* Footer-Copyright Main End  */

/* ---------------------------------------------------------------------------- */

/* NavbarPresentation  */

* {
    padding: 0;
    margin: 0;
}

body .nav__content h2 {
    padding-top: 30px;
    position: absolute;
    left: 50%;
    top: -1200px;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    background: white;
    width: 100%;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    z-index: 100000;
    transition-delay: 0s;
}

body .nav__content h2 .menu-sub-title{
    font-size: 22px!important;
    padding-top: 10px;
}

body.nav-active #interior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active2 #exterior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active3 #virtualReality .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active4 #panoramsExterior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active5 #panoramsInterior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active6 #videosExterior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active7 #videosInterior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active8 #configuratorExterior .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active9 #exteriorNormalTour .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

body.nav-active10 #interiorNormalTour .nav__content h2 {
    top: 0px;
    transition-delay: 0.1s;
    transition: all 0.8s;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
}

.site-content {
    max-width: 1100px;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.site-content__headline {
    font-weight: 200;
    color: #ffffff;
    font-size: calc(2vw + 10px);
}

.nav#interior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(200, 167, 129, 0.3) !important;
}

.nav#exterior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(0, 179, 60, 0.3);
}

.nav#virtualReality .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(217, 179, 140, 0.3);
}

.nav#panoramsInterior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(200, 167, 129, 0.3);
}

.nav#panoramsExterior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(0, 179, 60, 0.3);
}

.nav#videosExterior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(0, 179, 60, 0.3);
}

.nav#videosInterior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(0, 179, 60, 0.3);
}

.nav#configuratorExterior .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(200, 167, 129, 0.3);
}

.nav#exteriorNormalTour .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(0, 179, 60, 0.3);
}

.nav#interiorNormalTour .nav__content ul.nav__list li.nav__list-item .card .card-body {
    background: rgba(200, 167, 129, 0.3);
}

#interior {
    position: fixed;
    z-index: 10;
}

#interior:before,
#interior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#interior:after {
    background: white;
    transition-delay: 0s;
}

#interior:before {
    transition-delay: 0.1s;
}

#interior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#interior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#interior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#interior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#interior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#interior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#interior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#interior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}
/* ---------------------------------------------------------------------------- */
/* configurator start */

#configuratorExterior {
    position: fixed;
    z-index: 10;
}

#configuratorExterior:before,
#configuratorExterior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#configuratorExterior:after {
    background: white;
    transition-delay: 0s;
}

#configuratorExterior:before {
    transition-delay: 0.1s;
}

#configuratorExterior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#configuratorExterior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#configuratorExterior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#configuratorExterior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#configuratorExterior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#configuratorExterior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#configuratorExterior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#configuratorExterior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}
/* configurator end */
/* ---------------------------------------------------------------------------- */
#exterior {
    position: fixed;
    z-index: 10;
}

#exterior:before,
#exterior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#exterior:after {
    background: white;
    transition-delay: 0s;
}

#exterior:before {
    transition-delay: 0.1s;
}

#exterior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#exterior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#exterior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#exterior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#exterior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#exterior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#exterior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#exterior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

/* exteriorNormalTour start */
#exteriorNormalTour {
    position: fixed;
    z-index: 10;
}

#exteriorNormalTour:before,
#exteriorNormalTour:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#exteriorNormalTour:after {
    background: white;
    transition-delay: 0s;
}

#exteriorNormalTour:before {
    transition-delay: 0.1s;
}

#exteriorNormalTour .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#exteriorNormalTour .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#exteriorNormalTour .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#exteriorNormalTour .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#exteriorNormalTour .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#exteriorNormalTour .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#exteriorNormalTour .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#exteriorNormalTour .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

/* exteriorNormalTour end  */


/* interiorNormalTour start */
#interiorNormalTour {
    position: fixed;
    z-index: 10;
}

#interiorNormalTour:before,
#interiorNormalTour:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#interiorNormalTour:after {
    background: white;
    transition-delay: 0s;
}

#interiorNormalTour:before {
    transition-delay: 0.1s;
}

#interiorNormalTour .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#interiorNormalTour .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#interiorNormalTour .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#interiorNormalTour .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#interiorNormalTour .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#interiorNormalTour .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#interiorNormalTour .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#interiorNormalTour .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

/* interiorNormalTour end  */

#virtualReality {
    position: fixed;
    z-index: 10;
}

#virtualReality:before,
#virtualReality:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#virtualReality:after {
    background: white;
    transition-delay: 0s;
}

#virtualReality:before {
    transition-delay: 0.1s;
}

#virtualReality .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#virtualReality .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#virtualReality .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#virtualReality .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#virtualReality .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#virtualReality .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#virtualReality .nav__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#virtualReality .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

#panoramsExterior {
    position: fixed;
    z-index: 10;
}

#panoramsExterior:before,
#panoramsExterior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#panoramsExterior:after {
    background: white;
    transition-delay: 0s;
}

#panoramsExterior:before {
    transition-delay: 0.1s;
}

#panoramsExterior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#panoramsExterior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#panoramsExterior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#panoramsExterior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#panoramsExterior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#panoramsExterior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#panoramsExterior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#panoramsExterior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

#panoramsInterior {
    position: fixed;
    z-index: 10;
}

#panoramsInterior:before,
#panoramsInterior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#panoramsInterior:after {
    background: white;
    transition-delay: 0s;
}

#panoramsInterior:before {
    transition-delay: 0.1s;
}

#panoramsInterior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#panoramsInterior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#panoramsInterior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#panoramsInterior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#panoramsInterior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#panoramsInterior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#panoramsInterior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#panoramsInterior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

/* ---------------------------------------------------------------------------- */
/* videos Exterior */

#videosExterior {
    position: fixed;
    z-index: 10;
}

#videosExterior:before,
#videosExterior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#videosExterior:after {
    background: white;
    transition-delay: 0s;
}

#videosExterior:before {
    transition-delay: 0.1s;
}

#videosExterior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#videosExterior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#videosExterior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#videosExterior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#videosExterior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#videosExterior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#videosExterior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#videosExterior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

/* ---------------------------------------------------------------------------- */
/* videos Interior */

#videosInterior {
    position: fixed;
    z-index: 10;
}

#videosInterior:before,
#videosInterior:after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(234, 234, 234, 0.2);
    z-index: -1;
    transition: -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s, -webkit-transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
    -webkit-transform: translateX(0%) translateY(-100%);
            transform: translateX(0%) translateY(-100%);
}

#videosInterior:after {
    background: white;
    transition-delay: 0s;
}

#videosInterior:before {
    transition-delay: 0.1s;
}

#videosInterior .nav__content {
    position: fixed;
    top: -5000px;
    -webkit-transform: translate(0%, -50%);
            transform: translate(0%, -50%);
    width: 80%;
    text-align: center;
    font-size: calc(2vw + 10px);
    font-weight: 200;
}

#videosInterior .nav__list-item {
    position: relative;
    display: inline-block;
    transition-delay: 0.8s;
    opacity: 0;
    -webkit-transform: translate(0%, 100%);
            transform: translate(0%, 100%);
    transition: opacity 0.2s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease;
    transition: opacity 0.2s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    margin-right: 25px;
    margin-bottom: 20px;
    cursor: pointer;
}

#videosInterior .nav__list-item .card {
    width: 20rem;
    height: auto;
    display: inline-block;
}

#videosInterior .nav__list-item .card .card-body {
    padding: 0.8rem;
    color: #000;
}

#videosInterior .nav__list-item .card .card-body h5 {
    margin-bottom: 0px;
}

#videosInterior .nav__list-item .card:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
}

#videosInterior .nav__list {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    padding-top: 50px;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}

#videosInterior .nav__list::-webkit-scrollbar {
    width: 0px;
    background: transparent !important;
    display: none;
}

body.nav-active #interior {
    visibility: visible;
}

body.nav-active #interior:before,
body.nav-active #interior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active #interior:after {
    transition-delay: 0.1s;
}

body.nav-active #interior:before {
    transition-delay: 0s;
}

body.nav-active #interior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active #interior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active #interior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active #interior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active #interior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active #interior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active #interior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active #interior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active #interior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active #interior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active #interior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active #interior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active #interior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active #interior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active #interior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active #interior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active #interior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active #interior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active #interior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active #interior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active #interior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active #interior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active #interior .nav__content {
    top: 50%;
}
/* ---------------------------------------------------------------------------- */
/* configurator start */
body.nav-active8 #configuratorExterior {
    visibility: visible;
}

body.nav-active8 #configuratorExterior:before,
body.nav-active8 #configuratorExterior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active8 #configuratorExterior:after {
    transition-delay: 0.1s;
}

body.nav-active8 #configuratorExterior:before {
    transition-delay: 0s;
}

body.nav-active8 #configuratorExterior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active8 #configuratorExterior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active8 #configuratorExterior .nav__content {
    top: 50%;
}
/* configurator end */
/* ---------------------------------------------------------------------------- */

/* ---------------------------------------------------------------------------- */
/* ExteriorNormalTour start */
body.nav-active9 #exteriorNormalTour {
    visibility: visible;
}

body.nav-active9 #exteriorNormalTour:before,
body.nav-active9 #exteriorNormalTour:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active9 #exteriorNormalTour:after {
    transition-delay: 0.1s;
}

body.nav-active9 #exteriorNormalTour:before {
    transition-delay: 0s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active9 #exteriorNormalTour .nav__content {
    top: 50%;
}
/* ExteriorNormalTour end */
/* ---------------------------------------------------------------------------- */

/* InteriorNormalTour start */
body.nav-active10 #interiorNormalTour {
    visibility: visible;
}

body.nav-active10 #interiorNormalTour:before,
body.nav-active10 #interiorNormalTour:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active10 #interiorNormalTour:after {
    transition-delay: 0.1s;
}

body.nav-active10 #interiorNormalTour:before {
    transition-delay: 0s;
}

body.nav-active10 #interiorNormalTour .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active10 #interiorNormalTour .nav__content {
    top: 50%;
}
/* InteriorNormalTour end */
/* ---------------------------------------------------------------------------- */

body.nav-active2 #exterior {
    visibility: visible;
}

body.nav-active2 #exterior:before,
body.nav-active2 #exterior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active2 #exterior:after {
    transition-delay: 0.1s;
}

body.nav-active2 #exterior:before {
    transition-delay: 0s;
}

body.nav-active2 #exterior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active2 #exterior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active2 #exterior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active2 #exterior .nav__content {
    top: 50%;
}

body.nav-active3 #virtualReality {
    visibility: visible;
}

body.nav-active3 #virtualReality:before,
body.nav-active3 #virtualReality:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active3 #virtualReality:after {
    transition-delay: 0.1s;
}

body.nav-active3 #virtualReality:before {
    transition-delay: 0s;
}

body.nav-active3 #virtualReality .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active3 #virtualReality .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active3 #virtualReality .nav__content {
    top: 50%;
}

body.nav-active4 #panoramsExterior {
    visibility: visible;
}

body.nav-active4 #panoramsExterior:before,
body.nav-active4 #panoramsExterior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active4 #panoramsExterior:after {
    transition-delay: 0.1s;
}

body.nav-active4 #panoramsExterior:before {
    transition-delay: 0s;
}

body.nav-active4 #panoramsExterior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active4 #panoramsExterior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active4 #panoramsExterior .nav__content {
    top: 50%;
}

body.nav-active5 #panoramsInterior {
    visibility: visible;
}

body.nav-active5 #panoramsInterior:before,
body.nav-active5 #panoramsInterior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active5 #panoramsInterior:after {
    transition-delay: 0.1s;
}

body.nav-active5 #panoramsInterior:before {
    transition-delay: 0s;
}

body.nav-active5 #panoramsInterior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active5 #panoramsInterior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active5 #panoramsInterior .nav__content {
    top: 50%;
}

body.nav-active6 #videosExterior {
    visibility: visible;
}

body.nav-active6 #videosExterior:before,
body.nav-active6 #videosExterior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active6 #videosExterior:after {
    transition-delay: 0.1s;
}

body.nav-active6 #videosExterior:before {
    transition-delay: 0s;
}

body.nav-active6 #videosExterior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active6 #videosExterior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active6 #videosExterior .nav__content {
    top: 50%;
}

/* new menu  */

body.nav-active7 #videosInterior {
    visibility: visible;
}

body.nav-active7 #videosInterior:before,
body.nav-active7 #videosInterior:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active7 #videosInterior:after {
    transition-delay: 0.1s;
}

body.nav-active7 #videosInterior:before {
    transition-delay: 0s;
}

body.nav-active7 #videosInterior .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active7 #videosInterior .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active7 #videosInterior .nav__content {
    top: 50%;
}

/* ExteriorNormalTour start  */
body.nav-active9 #exteriorNormalTour {
    visibility: visible;
}

body.nav-active9 #exteriorNormalTour:before,
body.nav-active9 #exteriorNormalTour:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active9 #exteriorNormalTour:after {
    transition-delay: 0.1s;
}

body.nav-active9 #exteriorNormalTour:before {
    transition-delay: 0s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active9 #exteriorNormalTour .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active9 #exteriorNormalTour .nav__content {
    top: 50%;
}
/* ExteriorNormalTour end  */


/* InteriorNormalTour start  */
body.nav-active10 #interiorNormalTour {
    visibility: visible;
}

body.nav-active10 #interiorNormalTour:before,
body.nav-active10 #interiorNormalTour:after {
    -webkit-transform: translateX(0%) translateY(0%);
            transform: translateX(0%) translateY(0%);
}

body.nav-active10 #interiorNormalTour:after {
    transition-delay: 0.1s;
}

body.nav-active10 #interiorNormalTour:before {
    transition-delay: 0s;
}

body.nav-active10 #interiorNormalTour .nav__list-item {
    opacity: 1;
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    transition: opacity 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(0) {
    transition-delay: 0.5s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(1) {
    transition-delay: 0.6s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(2) {
    transition-delay: 0.7s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(3) {
    transition-delay: 0.8s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(4) {
    transition-delay: 0.9s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(5) {
    transition-delay: 1s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(6) {
    transition-delay: 1.1s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(7) {
    transition-delay: 1.2s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(8) {
    transition-delay: 1.3s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(9) {
    transition-delay: 1.4s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(10) {
    transition-delay: 1.5s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(11) {
    transition-delay: 1.6s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(12) {
    transition-delay: 1.7s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(13) {
    transition-delay: 1.8s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(14) {
    transition-delay: 1.9s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(15) {
    transition-delay: 2s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(16) {
    transition-delay: 2.1s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(17) {
    transition-delay: 2.2s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(18) {
    transition-delay: 2.3s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(19) {
    transition-delay: 2.4s;
}

body.nav-active10 #interiorNormalTour .nav__list-item:nth-child(20) {
    transition-delay: 2.5s;
}

body.nav-active10 #interiorNormalTour .nav__content {
    top: 50%;
}
/* InteriorNormalTour end  */

@-webkit-keyframes menu1 {
    from {
        opacity: 0;
        -webkit-transform: rotateX(-180deg);
                transform: rotateX(-180deg);
    }
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@keyframes menu1 {
    from {
        opacity: 0;
        -webkit-transform: rotateX(-180deg);
                transform: rotateX(-180deg);
    }
    to {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
}

@-webkit-keyframes menu2 {
    from {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
    to {
        opacity: 0;
        -webkit-transform: rotateX(-180deg);
                transform: rotateX(-180deg);
    }
}

@keyframes menu2 {
    from {
        opacity: 1;
        -webkit-transform: rotateX(0deg);
                transform: rotateX(0deg);
    }
    to {
        opacity: 0;
        -webkit-transform: rotateX(-180deg);
                transform: rotateX(-180deg);
    }
}

#main-menu ul#navigation li.navigation-dropdown:hover {
    cursor: pointer;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu {
    width: 100%;
    height: auto;
    list-style: none;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item {
    list-style: none;
    background-repeat: no-repeat;
    font: 300 31px "Roboto", "Open Sans", "Arial", sans-serif;
    line-height: 57px;
    padding: 10px;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item a {
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item a span {
    display: none;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item a:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    height: 4px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item a.active span {
    display: none;
}

#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item:hover a.navigation-link:before,
#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item:hover a.navigation-link:active::before,
#main-menu ul#navigation li.navigation-dropdown ul.sub-menu li.sub-menu-item:hover a.navigation-link:focus::before {
    right: 0;
}

#main-menu.animated {
    top: -5000px !important;
    flex-direction: row !important;
    left: 0%;
    width: 100%;
    margin: auto;
    z-index: 3;
    right: 0%;
    -webkit-transform: none;
            transform: none;
    transition: none;
    max-height: 207px !important;
}

#main-menu.animated #navigation {
    height: 152px;
    padding-top: 0px !important;
    margin: 0;
    width: 100%;
    align-items: start;
}

#main-menu.animated #navigation li.navigation-item:nth-child(5) {
    -webkit-animation-delay: 675ms !important;
            animation-delay: 675ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(4) {
    -webkit-animation-delay: 525ms !important;
            animation-delay: 525ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(3) {
    -webkit-animation-delay: 375ms !important;
            animation-delay: 375ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(2) {
    -webkit-animation-delay: 225ms !important;
            animation-delay: 225ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(1) {
    -webkit-animation-delay: 75ms !important;
            animation-delay: 75ms !important;
}

#main-menu.animated #navigation li.navigation-item {
    opacity: 1;
    -webkit-animation: menu2 ease-in-out forwards;
            animation: menu2 ease-in-out forwards;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    background: #fff;
    font: 700 15px "Roboto", "Open Sans", "Arial", sans-serif;
    padding-left: 20px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 0px !important;
}

#main-menu.animated #navigation li.navigation-item a.navigation-link {
    padding: 10px 10px 10px 40px;
    width: 100%;
}

#main-menu.animated #navigation li.navigation-item a.navigation-link.active span {
    display: inline;
}

#main-menu.minimized {
    padding-bottom: 0px!important;
    top: 0px!important;
    flex-direction: row !important;
    left: 0%;
    width: 100%;
    margin: auto;
    /* z-index: 300000000000000000000; */
    right: 0%;
    -webkit-transform: none;
            transform: none;
    transition: none;
    /* max-height: 207px !important; */
}

#main-menu.minimized #navigation {
    background-color: white;
    height: 100%!important;
    margin: 0;
    width: 100%;
    align-items: start;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 30px;
    padding-bottom: 100px!important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(1) {
    -webkit-animation-delay: 75ms !important;
            animation-delay: 75ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(2) {
    -webkit-animation-delay: 225ms !important;
            animation-delay: 225ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(3) {
    -webkit-animation-delay: 375ms !important;
            animation-delay: 375ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(4) {
    -webkit-animation-delay: 525ms !important;
            animation-delay: 525ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(5) {
    -webkit-animation-delay: 675ms !important;
            animation-delay: 675ms !important;
}

#main-menu.minimized #navigation li.navigation-item {
    opacity: 0;
    -webkit-animation: menu1 ease-in-out forwards;
            animation: menu1 ease-in-out forwards;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    background: #fff;
    font: 700 15px "Roboto", "Open Sans", "Arial", sans-serif;
    padding-left: 20px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 0px !important;
}

#main-menu.minimized #navigation li.navigation-item a.navigation-link {
    padding: 10px 10px 10px 40px;
    margin-left: 0px!important;
    padding-left: 50px!important;
    font-size: 18px;
    width: 100%;
}

#main-menu.minimized #navigation li.navigation-item a.navigation-link.active span {
    display: inline;
}

#main-menu.minimized #navigation li.navigation-item:last-child {
    border-bottom: none;
}

#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link:before,
#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link:active::before,
#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link:focus::before {
    right: 100%;
}

#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link {
    transition: color 0.3s ease;
}

#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link span {
    display: inline;
    transition: display 0.3s ease;
}

#main-menu.opened {
    right: 20%;
}

#main-menu.opened .logo2 {
    display: block;
}

#main-menu.opened .logo2:hover {
    cursor: pointer;
}

#main-menu.opened #navigation {
    padding-top: 30px;
    overflow-y: auto;
}

#main-menu {
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-top: 0px;
    text-align: center;
    position: fixed;
    height: 100vh;
    z-index: 15;
    width: 19%;
    right: -32.4%;
    -webkit-transform: translateX(280px);
            transform: translateX(280px);
}

#main-menu.minimized {
    padding-left: 0px!important;
    padding-right: 0px!important;
}

#main-menu .logo2 {
    top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    max-height: 350px;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
    display: none;
}

#main-menu #navigation {
    padding-top: 80px;
    display: flex;
    align-items: start;
    flex-direction: column;
    box-sizing: border-box;
    max-width: 1160px;
    margin: 0 auto;
}

#main-menu #navigation li.navigation-item {
    list-style: none;
    background-repeat: no-repeat;
    font: 300 31px "Roboto", "Open Sans", "Arial", sans-serif;
    line-height: 57px;
    padding: 10px;
}

#main-menu #navigation li.navigation-item a.navigation-link {
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
}

#main-menu #navigation li.navigation-item a.navigation-link span {
    display: none;
}

#main-menu #navigation li.navigation-item a.navigation-link:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    height: 4px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

#main-menu #navigation li.navigation-item a.navigation-link.active span {
    display: none;
}

#main-menu #navigation li.navigation-item:hover a.navigation-link:before,
#main-menu #navigation li.navigation-item:hover a.navigation-link:active::before,
#main-menu #navigation li.navigation-item:hover a.navigation-link:focus::before {
    right: 0;
}

#main-menu #navigation li.navigation-item:hover ul.sub-menu li a.navigation-link {
    color: inherit !important;
}

#main-menu #navigation li.navigation-item ul.sub-menu li:hover a.navigation-link {
    color: #2e5cba !important;
}

#menu-button {
    max-width: 44px;
    max-height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 300000000000000000000;
    position: absolute;
    top: 10px;
    right: 30px;
    background: #000;
    color: white;
    border-radius: 50%;
    border: 4px solid transparent;
}

#menu-button:hover {
    background: transparent;
    border: 4px solid #000;
    transition: background 0.3s ease;
    color: #000;
}

#menu-button:focus {
    outline: none;
    box-shadow: none !important;
}

#menu-button.exterior-interior {
    top: 40px;
}

.full-screen-intro.transformed {
    padding-bottom: 0px;
    background-color: rgba(245, 243, 239, 0.3);
    box-shadow: rgba(0, 0, 0, 0.3) 12px 0px 14px -3px;
    overflow-y: hidden !important;
    height: 100%;
    -webkit-transform: translate(-190px) rotateY(45deg) translateZ(0);
    transform: translate(-190px) rotateY(45deg) translateZ(0);
}

.full-screen-intro.transformed .logo {
    display: none;
}

.full-screen-intro.transformed .full-screen-text {
    padding-left: 50px;
}

.full-screen-intro:after {
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-animation: AnimationIn 10s infinite ease-in-out;
            animation: AnimationIn 10s infinite ease-in-out;
    background-size: cover;
    z-index: inherit;
    z-index: 1;
}

.full-screen-intro {
    align-items: center;
    text-align: center;
    justify-content: center;
    transition: transform 0.7s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
}

.full-screen-intro .logo {
    position: absolute;
    z-index: 2000;
    right: 112px;
    top: 10px;
    width: 150px;
    max-width: 350px;
    max-height: 350px;
}

.full-screen-intro .configurator-logo {
    position: absolute;
    z-index: 2000;
    left: 1%;
    top: 0px;
    width: 100px;
    max-width: 50px!important;
    max-height: 50px!important;
}

.full-screen-intro .configurator-logo img {
    max-width: 120px!important;
    max-height: 120px!important;
}

.full-screen-intro .logo.exterior-interior {
    max-width: 250px;
    max-height: 250px;
}

.full-screen-intro .full-screen-text {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 450px;
    width: auto;
    max-width: 1200px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    z-index: 2;
    color: #fff !important;
}

.full-screen-intro .full-screen-text h4 {
    font-size: 22px;
    font-weight: bold;
    font-family: "Roboto", "Open Sans", "Arial", sans-serif;
}

.full-screen-intro .full-screen-text h1 {
    font: 700 80px "Roboto", "Open Sans", "Arial", sans-serif;
    padding-top: 20px;
}

.full-screen-intro .full-screen-text h5 {
    padding-top: 20px;
    font-family: "Roboto", "Open Sans", "Arial", sans-serif;
}

.full-screen-intro .full-screen-text #view {
    margin-top: 50px;
    padding: 15px 38px;
    background: transparent;
    border: 1px solid white;
    text-transform: uppercase;
    font-weight: bold;
}

.full-screen-intro .full-screen-text #view a {
    font-size: 13px;
    color: white;
    text-decoration: none;
}

.full-screen-intro .full-screen-text #view:hover {
    background: white;
    transition: background 0.9s;
}

.full-screen-intro .full-screen-text #view:hover a {
    color: #000;
    transition: color 0.9s;
}

.full-screen-intro .full-screen-text #view:focus {
    outline: none !important;
    box-shadow: none !important;
}

#body {
    --perspective: none;
    position: fixed;
    -webkit-perspective: var(--perspective);
            perspective: var(--perspective);
    overflow-x: visible;
    width: 100%;
    height: 100%;
}

#body.open {
    --perspective: 1500px;
}

#main-menu.minimized {
    padding-bottom: 0px!important;
    top: 0px!important;
    flex-direction: row !important;
    left: 0%;
    width: 100%;
    min-height: 100vh;
    height: 100%!important;
    margin: auto; 
    z-index: 1100001;
    /* z-index: 300000000000000000000; */
    right: 0%;
    -webkit-transform: none;
            transform: none;
    transition: none;
    /* max-height: 207px !important; */
}

#main-menu.minimized #navigation {
    height: 100%;
    margin: 0;
    width: 100%;
    align-items: start;
    padding-top: 30px;
    padding-bottom: 60px;
    overflow-y: auto;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(1) {
    -webkit-animation-delay: 75ms !important;
            animation-delay: 75ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(2) {
    -webkit-animation-delay: 225ms !important;
            animation-delay: 225ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(3) {
    -webkit-animation-delay: 375ms !important;
            animation-delay: 375ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(4) {
    -webkit-animation-delay: 525ms !important;
            animation-delay: 525ms !important;
}

#main-menu.minimized #navigation li.navigation-item:nth-child(5) {
    -webkit-animation-delay: 675ms !important;
            animation-delay: 675ms !important;
}

#main-menu.minimized #navigation li.navigation-item {
    opacity: 0;
    -webkit-animation: menu1 ease-in-out forwards;
            animation: menu1 ease-in-out forwards;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    background: #fff;
    font: 700 15px "Roboto", "Open Sans", "Arial", sans-serif;
    padding-left: 20px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 0px !important;
}

#main-menu.minimized #navigation li.navigation-item#innen a.navigation-link {
    padding-left: 25px!important;
    font-size: 22px;
}

#main-menu.minimized #navigation li.navigation-item#auben a.navigation-link {
    padding-left: 25px!important;
    font-size: 22px;
}

#main-menu.minimized #navigation li.navigation-item#home a.navigation-link{
    padding-left: 25px!important;
    font-size: 22px;
}

#main-menu.minimized #navigation li.navigation-item a.navigation-link {
    padding: 10px 10px 10px 40px;
    margin-left: 0px!important;
    padding-left: 50px!important;
    font-size: 18px;
    width: 100%;
}

#main-menu.minimized #navigation li.navigation-item a.navigation-link.active span {
    display: inline;
}

#main-menu.minimized #navigation li.navigation-item:last-child {
    border-bottom: none;
}

#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link:before,
#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link:active::before,
#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link:focus::before {
    right: 100%;
}

#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link {
    transition: color 0.3s ease;
}

#main-menu.minimized #navigation li.navigation-item:hover a.navigation-link span {
    display: inline;
    transition: display 0.3s ease;
}

#main-menu.animated {
    top: -5000px !important;
    flex-direction: row !important;
    left: 0%;
    width: 100%;
    margin: auto;
    z-index: 3;
    right: 0%;
    -webkit-transform: none;
            transform: none;
    transition: none;
    max-height: 207px !important;
}

#main-menu.animated #navigation {
    height: 152px;
    padding-top: 0px !important;
    margin: 0;
    width: 100%;
    align-items: start;
}

#main-menu.animated #navigation li.navigation-item:nth-child(5) {
    -webkit-animation-delay: 675ms !important;
            animation-delay: 675ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(4) {
    -webkit-animation-delay: 525ms !important;
            animation-delay: 525ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(3) {
    -webkit-animation-delay: 375ms !important;
            animation-delay: 375ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(2) {
    -webkit-animation-delay: 225ms !important;
            animation-delay: 225ms !important;
}

#main-menu.animated #navigation li.navigation-item:nth-child(1) {
    -webkit-animation-delay: 75ms !important;
            animation-delay: 75ms !important;
}

#main-menu.animated #navigation li.navigation-item {
    opacity: 1;
    -webkit-animation: menu2 ease-in-out forwards;
            animation: menu2 ease-in-out forwards;
    -webkit-animation-duration: 300ms;
            animation-duration: 300ms;
    background: #fff;
    font: 700 15px "Roboto", "Open Sans", "Arial", sans-serif;
    padding-left: 20px;
    text-align: left;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    padding: 0px !important;
}

#main-menu.animated #navigation li.navigation-item a.navigation-link {
    padding: 10px 10px 10px 40px;
    width: 100%;
}

#main-menu.animated #navigation li.navigation-item a.navigation-link.active span {
    display: inline;
}

#fullScreen.configurator-screen {
    overflow-y: hidden!important;
}

#fullScreen .slider .row .slide .imgParent {
    position: relative;
}

#fullScreen .slider.open .row .slide.open .imgParent {
    display: flex!important;
    align-items: center;
    max-width: 100%;
    height: 100%;
    justify-content: center;
}

#fullScreen.logo-right .logo {
    left: 75%!important;
}

button.btn-picker:focus, button.btn-picker:active, button.btn-picker:focus:active {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
}

.img-overlay {
    background: rgba(0, 0, 0, 0.56);
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    position: absolute;
    left: 0px;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100%;
    z-index: 1;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

#fullScreen .row .col-md-6 .pnlm-ui .pnlm-controls-container {
    display: none!important;
}

#fullScreen .row .col-md-6 .pnlm-ui .pnlm-about-msg {
    display: none!important;
}

#fullScreen .row .col-md-6 {
    position: relative;
}

#fullScreen .row .col-md-6 .img-overlay {
    max-height: 100%;
}

#fullScreen .row .col-md-6:hover .img-overlay {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

#fullScreen .row .col-md-6:hover .full-screen-btn {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
    transition: all 0.3s 0.2s ease-in-out;
}

#fullScreen .row .col-md-6:hover .full-screen-btn:hover span i {
    font-size: 4em;
    transition: all 0.2s 0.1s ease-in-out;
}

#fullScreen .row .col-md-6:hover {
    cursor: pointer;
}

.full-screen-btn {
    border-radius: 2px;
    color: #ffffff;
    font-size: 15px;
    font-family: Default;
    font-weight: inherit;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1.3);
            transform: translateX(-50%) translateY(-50%) scale(1.3);
    opacity: 0;
    transition: all 0.3s 0.2s ease-in-out;
    z-index: 99;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    padding: 10px 10px;
    box-sizing: border-box;
    text-align: center;
}

#fullScreen {
    overflow-y: auto;
}

#fullScreen #slider.open .row {
    height: 100%;
}

#fullScreen .row {
    height: auto;
    padding-top: 130px !important;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 70px;
}

#fullScreen .row.configurator-row {
    padding-top: 70px!important;
    max-height: 700px;
    max-width: 1880px;
    margin: 0 auto;
}

#fullScreen .row.row-without-top {
    padding-top: 10px!important;
    padding-left: 5px!important;
    padding-right: 5px!important;
}

#fullScreen .row .col-12 img {
    border-radius: 10px;
}

#fullScreen .row .col-12 .pnlm-container {
    border-radius: 10px;
}

.slider {
    position: relative;
    width: 100%;
    height: 100% !important;
    margin: 0 auto;
    z-index: 2;
}

#close-slider {
    display: none !important;
    background: #fff;
    opacity: 0.8;
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 10000000000;
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

#close-slider:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background: #222;
    cursor: pointer;
}

#close-slider i {
    color: #ccc;
    line-height: 48px;
}

#fullScreen .slider.open {
    overflow: hidden;
}

#fullScreen .slider.open .row {
    padding: 0px !important;
    margin: 0px !important;
}

#fullScreen .slider.open .row {
    position: relative;
}

.logo {
    max-height: 100px;
    max-width: 250px;
}

.logo.configurator-logo {
    max-height: 80px!important;
    max-width: 200px!important;
}

#fullScreen .slider.open .row .col-md-6 {
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
    max-height: 100%!important;
    flex: none !important;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: none;
}

#fullScreen .slider.open .row .col-md-6.open {
    display: block;
}

#fullScreen .slider.open.pano .slider-control {
    top: 90%;
}

#fullScreen .slider.open.pano .row .col-md-6.open {
    opacity: 0;
}

#fullScreen .slider.open.pano .row .col-md-6.open.now {
    opacity: 1;
    z-index: 1;
}

#fullScreen .slider.open.pano .row .col-md-6.open.now .pnlm-container .pnlm-ui .pnlm-controls-container {
    display: none;
}

#fullScreen .slider.open .row .slide .pnlm-container .pnlm-ui .pnlm-controls-container {
    display: none;
}

.left90 {
    margin-left: -150px!important;
    box-shadow: none!important;
    z-index: -1;
}

#fullScreen .slider.open .row .slide {
    padding: 0px !important;
    margin: 0px !important;
    max-width: 100% !important;
    max-height: 100%!important;
    flex: none !important;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    position: absolute;
    z-index: 0;
}

#fullScreen .slider.open .row .slide.open {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    z-index: 10;
}

#fullScreen .slider.open .row .slide.open img.img-fluid {
    width: auto;
    height: 100%;
}

/* #fullScreen .slider.open.pano .row .slide.open {
    opacity: 0;
} */

#fullScreen .slider.open.pano .row .slide.open.now {
    opacity: 1;
    z-index: 100;
}

#fullScreen .slider.open.pano .row .slide.open.now .pnlm-container .pnlm-ui .pnlm-controls-container {
    display: none;
}

.slider.open .row .slide {
    width: 100% !important;
    max-width: 100% !important;
}

.slider.open .row .slide .img-overlay {
    display: none;
}

.slider.open .row .slide .full-screen-btn {
    display: none;
}

#fullScreen .slider.open .row .col-12 img {
    border-radius: 0px;
}

#fullScreen .slider.open .row .col-12 .pnlm-container {
    border-radius: 0px;
    height: 100% !important;
}

.slider.open {
    height: 100% !important;
    width: 100% !important;
    position: static !important;
    padding-bottom: 30px;
    background: #000;
}

.slider.open .row .col-md-6 {
    width: 100% !important;
    max-width: 100% !important;
}

.slider.open .row .col-md-6 .img-overlay {
    display: none;
}

.slider.open .row .col-md-6 .full-screen-btn {
    display: none;
}

.slider.open #close-slider {
    display: block !important;
}

.slider .slider-control {
    display: none;
    transition: all 0.4s;
    width: 48px;
    height: 48px;
    position: absolute;
    top: 50%;
    margin-top: -24px;
    z-index: 1100000;
    border-radius: 50%;
    background: #fff;
    opacity: 0.8;
    cursor: pointer;
    line-height: 48px;
    text-align: center;
}

.slider.open .slider-control {
    display: block;
}

.slider .slider-control:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    background: #222;
}

.slider .slider-control.slide-left {
    left: 24px;
}

.slider .slider-control.slide-right {
    right: 24px;
}

.slider .slider-control i {
    color: #ccc;
    line-height: 48px;
}

#fullScreen #slider.open .slide.open h5 {
    position: absolute;
    bottom: 0;
    background: #000;
    margin-bottom: 0px;
}

#fullScreen #slider.open .col-12.open img {
    max-width: 100% !important;
    width: auto;
    height: 100%;
    max-height: 100% !important;
    box-shadow: none !important;
}

#fullScreen #slider.open:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

#fullScreen #slider.open:-ms-fullscreen {
    width: 100%;
    height: 100%;
}

#fullScreen #slider.open:fullscreen {
    width: 100%;
    height: 100%;
}

#fullScreen #slider.open .col-12.open {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#fullScreen #slider.open .col-12.open .pnlm-container {
    width: 100%;
    height: 100%!important;
}
/* Panorams Mini Menu start*/

#panoramsMenu ul li img.panoramsImg {
    max-height: 90px !important;
    height: 90px!important;
    width: auto;
}

#panoramsMenu ul li:hover {
    cursor: pointer;
}

/* #menuPano {
    padding-top: 65px;
} */

#menu-left-title {
    font-size: 17px;
    font-weight: 400;
}

.menuBottomPanorama {
    margin-bottom: 0px!important;
    padding-bottom: 0px!important;
}

#menuPano li.list-group-item {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

#menuPano .miniControl {
    transition: background .3s ease;
}

#menuPano .miniControl:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.4);
    transition: background .3s ease;
}

#menuPano .miniControl.disabled {
    transition: none!important;
}

#menuPano .miniControl.disabled:hover {
    transition: none!important;
    cursor: default!important;
}

#fullScreen .logoPano {
    position: absolute;
    z-index: 2000;
    top: 1px;
    right: 170px!important;
    display: block;
    max-width: 250px;
    width: 350px;
    max-height: 250px;
}

#projectNameTour {
    display: block;
    z-index: 2000;
    position: absolute;
    width: 100%;
    background: transparent;
    top: 0px;
    left: 0px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
    background: rgba(0, 0, 0, 0.3);
    color: #fff!important;
}

.loader {
    margin: 100px auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    top: 25%;
    left: 0%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #0071bc, 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.5), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.7), 1.8em -1.8em 0 0em #0071bc, 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.5), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.7), 2.5em 0em 0 0em #0071bc, 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.5), 2.5em 0em 0 0em rgba(102, 194, 255, 0.7), 1.75em 1.75em 0 0em #0071bc, 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.5), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.7), 0em 2.5em 0 0em #0071bc, -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.5), 0em 2.5em 0 0em rgba(102, 194, 255, 0.7), -1.8em 1.8em 0 0em #0071bc, -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.5), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.7), -2.6em 0em 0 0em #0071bc, -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.5), -2.6em 0em 0 0em rgba(102, 194, 255, 0.7), -1.8em -1.8em 0 0em #0071bc;
    }
}

@keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #0071bc, 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.5), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.7);
    }
    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.7), 1.8em -1.8em 0 0em #0071bc, 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.5);
    }
    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.5), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.7), 2.5em 0em 0 0em #0071bc, 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.5), 2.5em 0em 0 0em rgba(102, 194, 255, 0.7), 1.75em 1.75em 0 0em #0071bc, 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.5), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.7), 0em 2.5em 0 0em #0071bc, -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.2), -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.5), 0em 2.5em 0 0em rgba(102, 194, 255, 0.7), -1.8em 1.8em 0 0em #0071bc, -2.6em 0em 0 0em rgba(102, 194, 255, 0.2), -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.5), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.7), -2.6em 0em 0 0em #0071bc, -1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2);
    }
    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(102, 194, 255, 0.2), 1.8em -1.8em 0 0em rgba(102, 194, 255, 0.2), 2.5em 0em 0 0em rgba(102, 194, 255, 0.2), 1.75em 1.75em 0 0em rgba(102, 194, 255, 0.2), 0em 2.5em 0 0em rgba(102, 194, 255, 0.2), -1.8em 1.8em 0 0em rgba(102, 194, 255, 0.5), -2.6em 0em 0 0em rgba(102, 194, 255, 0.7), -1.8em -1.8em 0 0em #0071bc;
    }
}

.opacity1 {
    opacity: 1!important;
}

.opacity0 {
    opacity: 0!important;
}

.myWidth {
    height: 5em;
    width: 100%;
}

.loader1,
.loader1:before,
.loader1:after {
    background: #0071bc;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader1 {
    color: #0071bc;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader1:before,
.loader1:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader1:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader1:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

.loader2 {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #333;
    background: linear-gradient(to right, #333 10%, rgba(0, 0, 0, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.loader2:before {
    width: 50%;
    height: 50%;
    background: #333;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader2:after {
    background: #fff;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#projectName {
    position: absolute;
    z-index: 2;
    top: 10px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    height: 100px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

.z1 {
    z-index: 100000!important;
}

#projectName {
    font-size: 3em !important;
    text-align: center;
    top: 35px;
    white-space: nowrap;
    font-family: sans-serif;
    letter-spacing: 0.1em;
    text-shadow: 0.5px 0.5px 0 #ccc, 0.5px 1px 0 #ccc, 0.5px 1.5px 0 #ccc, 0.5px 2px 0 #ccc, 0.5px 2.5px 0 #ccc, 0.5px 3px 0 #ccc, 0.5px 3.5px 0 #ccc, 0.5px 4px 0 #ccc, 2.5px 6.5px 7.5px grey;
}

#fullScreen.exterior-interior.logo-right .logo {
    left: 80%!important;
    padding-top: 45px;
}

#fullScreen #slider .row .openSlider {
    padding-bottom: 40px;
}

#fullScreen #slider .row .col-md-6 .titleImg {
    /* position: absolute; */
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin-bottom: 0px;
    /* max-width: 97%!important; */
    max-width: 100%!important;
}

.titleImg {
    /* position: absolute; */
    bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin-bottom: 0px;
    max-width: 97%!important;
}

.pnlm-lmsg {
    display: none!important;
}

button.vjs-big-play-button {
    position: absolute;
    top: 50%!important;
    left: 45%!important;
    right: 50%!important;
    bottom: 50%!important;
    margin: auto!important;
}

li#home a {
    color: #333;
}

li#home:hover a {
    color: #333
}

li#home a:hover {
    color: #333
}

button#maximizes:focus, button#maximizes:active, button#maximizes:focus:active {
    outline: none;
    -webkit-appearance: none;
    box-shadow: none;
}

#front-side-btn:focus, #front-side-btn:active, #front-side-btn:focus:active {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
}

#back-side-btn:focus, #back-side-btn:active, #back-side-btn:focus:active {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
}

/* Panorams Mini Menu end*/

/* ---------------------------------------------------------------------------- */


/* panorama tour menu left start */

#fullScreen .row.panorama_tour_menu {
    padding: 0px !important;
    padding-top: 0px!important;
    height: auto!important;
}

#menuLeft {
    position: absolute;
    height: 100vh;
    width: 350px;
    z-index: 1000000000;
    overflow-y: auto;
}

#menuLeft.left0 {
    margin-left: -350px;
}


/* panorama tour menu left end */


/* ---------------------------------------------------------------------------- */


/* top and bottom panorams menu links on left start  */

#fullScreen .row.panorama_tour_menu.menuTopPanorama {
    padding: 5px!important;
}

.menuTopPanorama {
    overflow-y: hidden;
    /* position: absolute; */
    /* top: 41px; */
    left: 0px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    list-style: none;
    /* width: 190px; */
    max-height: 155px;
}

.menuTopPanorama:hover {
    overflow-y: auto;
}

#fullScreen .row.panorama_tour_menu.menuBottomPanorama {
    padding: 5px!important;
}

.menuBottomPanorama {
    overflow-y: hidden;
    /* position: absolute; */
    /* bottom: 20px; */
    left: 0px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    list-style: none;
    /* width: 190px; */
    max-height: 125px;
}

.menuBottomPanorama:hover {
    overflow-y: auto;
}

/* top and bottom panorams menu links on left end  */

/* configurator menu items click hover actions start  */

.sw-roof-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 1px;
    transition: box-shadow .2s ease-out;
}

.sw-roof-btn:focus, .sw-roof-btn:active, .sw-roof-btn:focus:active {
    outline: none!important;
    -webkit-appearance: none!important;
    box-shadow: none!important;
} 

.sw-roof-btn.active {
    box-shadow: none;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

.sw-gutters-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 2px;
    transition: box-shadow .2s ease-out;
}

.sw-gutters-btn:focus, .sw-gutters-btn:active, .sw-gutters-btn:focus:active {
    outline: none!important;
    -webkit-appearance: none!important;
    box-shadow: none!important;
}

.sw-gutters-btn.active {
    box-shadow: none;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

.sw-walls-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 2px;
    transition: box-shadow .2s ease-out;
}

.sw-walls-btn:focus, .sw-walls-btn:active, .sw-walls-btn:focus:active {
    outline: none!important;
    -webkit-appearance: none!important;
    box-shadow: none!important;
}

.sw-walls-btn.active {
    box-shadow: none!important;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

.sw-walls_second-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 2px;
    transition: box-shadow .2s ease-out;
}

.sw-walls_second-btn:focus, .sw-walls_second-btn:active, .sw-walls_second-btn:focus:active {
    outline: none!important;
    -webkit-appearance: none!important;
    box-shadow: none!important;
}

.sw-walls_second-btn.active {
    box-shadow: none!important;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

.sw-windows-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 2px;
    transition: box-shadow .2s ease-out;
}

.sw-windows-btn:focus, .sw-windows-btn:active, .sw-windows-btn:focus:active {
    outline: none!important;
    -webkit-appearance: none!important;
    box-shadow: none!important;
}

.sw-windows-btn.active {
    box-shadow: none!important;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

.sw-door_color-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 2px;
    transition: box-shadow .2s ease-out;
}

.sw-door_color-btn:focus, .sw-door_color-btn:active, .sw-door_color-btn:focus:active {
    outline: none!important;
    -webkit-appearance: none!important;
    box-shadow: none!important;
}

.sw-door_color-btn.active {
    box-shadow: none!important;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

.sw-door_shapes-btn {
    box-shadow: rgba(0,0,0,0.2) 6px 6px 2px 2px;
    transition: box-shadow .2s ease-out;
}

.sw-door_shapes-btn:focus, .sw-door_shapes-btn:active, .sw-door_shapes-btn:focus:active {
   outline: none!important;
   -webkit-appearance: none!important;
   box-shadow: none!important;
}

.sw-door_shapes-btn.active {
    box-shadow: none!important;
    transition: box-shadow .2s ease-in;
    border: 2px solid #333333!important;
}

#small {
    font-size: 20px;
}

/* configurator menu items click hover actions end  */

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* responsive start */

/* resposive configurator start */
@media screen and (max-width: 576px) {
    .configurator-container {
        max-height: 220px;
    }
    .row>*{
        margin-bottom: 4rem;
    }
    .allHighResBtn {
        max-width: 270px!important;
        margin-bottom: 0px!important;
        /* right: 20%!important; */
        /* bottom: 0px; */
        top: 85vh!important;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .configurator-container {
        max-height: 350px;
    }
    .allHighResBtn {
        max-width: 270px!important;
        margin-bottom: 0px!important;
        /* right: 35%!important; */
        /* bottom: 0px; */
        top: 90vh!important;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .configurator-container {
        max-height: 470px;
    }
    .allHighResBtn {
        max-width: 270px!important;
        margin-bottom: 0px!important;
        /* bottom: 0px; */
        top: 90vh!important;
    }
    .highResBtn {
        max-width: 270px!important;
        top: 90vh!important;
        left: 0px!important;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .configurator-container {
        max-height: 500px;
    }
    .allHighResBtn {
        max-width: 100px!important;
        margin-right: 0px!important;
    }
    .highResBtn {
        max-width: 100px!important;
        margin-right: 440px!important;
    }
    #panoramsMenu ul li img.panoramsImg {
        width: 90px!important;
        height: auto!important;
    }
    img.logo {
        /*left: 123%!important;*/
        width: 80px!important;
    }
}

img.logo {
    /*left: 103%!important;*/
}
/* resposive configurator end */

@media(min-width: 1758px) {
    .rowi {
        bottom:10px!important;
    }
    .rowi ul li a p b {
        font-size: inherit!important;
    }
    .allHighResBtn {
        font-size: 15px!important;
    }
    .highResBtn {
        font-size: 15px!important;
    }
}

@media(min-width: 992px) {
    #fullScreen #slider.open .col-12.open .pnlm-container {
        width: 80%!important;
        height: 85%!important;
    }
}

@media(min-width: 992px) and (max-width: 1900px) {
    .menuTopPanorama  {
        padding-left: 0px!important;
    }
}

@media(min-width: 1200px) and (max-width: 1900px) {
    /* .menuTopPanorama  {
        padding-left: 0px!important;
    } */
    .allHighResBtn {
        max-width: 120px!important;
        margin-right: 0px!important;
    }
    .highResBtn {
        max-width: 120px!important;
        margin-right: 440px!important;
    }
    /*#panoramsMenu ul li img.panoramsImg {
        width: 130px!important;
        height: auto!important;
    }*/
    img.logo {
        /*left: 123%!important;*/
        width: 80px!important;
    }
}

@media (min-width: 1600px) and (max-width: 1900px) {
    .nav__content {
        width: 70%;
    }
}

@media (min-width: 1200px) {
    .navbar-nav li.active a {
        border: 2px groove #ff9900;
        border-bottom: none;
        border-radius: 50%;
    }
    .rowi {
        max-width: 80%;
    }
    .rowi.panoramatour {
        max-width: 80%;
    }
    .slider-control.slide-left {
        left: 15%!important;
    }
    .slider-control.slide-right {
        right: 15%!important;
    }
}

@media(max-width: 1600px) {
    #main-menu.opened {
        right: 25%;
        width: 22%;
    }
    #main-menu.opened ul#navigation li.navigation-item {
        padding: 0px;
        font-size: 22px;
    }
    #main-menu.opened ul#navigation li.navigation-item a {
        font-size: 22px;
    }
}

@media (max-width: 1300px) {
    #main-menu #navigation {
        padding-top: 80px;
    }
    #main-menu.opened {
        right: 30%;
        width: 22%;
    }
    #main-menu.opened ul#navigation li.navigation-item {
        padding: 0px;
        font-size: 22px;
    }
    #main-menu.opened ul#navigation li.navigation-item a {
        font-size: 22px;
    }
}

@media(max-width: 1200px) {
    .menuTopPanorama,
    .menuBottomPanorama {
        visibility: hidden!important;
    }
    #fullScreen .slider.open .row .slide.open img.img-fluid {
        width: 100%;
        height: auto;
    }
    #fullScreen {
        height: 100%!important;
    }
    #fullScreen.configurator-screen {
        overflow-y: scroll!important;
    }
}

@media (max-width: 1100px) {
    #main-menu.opened {
        right: 35%;
        width: 22%;
    }
    #main-menu.opened ul#navigation li.navigation-item {
        padding: 0px;
        font-size: 22px;
    }
    #main-menu.opened ul#navigation li.navigation-item a {
        font-size: 22px;
    }
}

@media (max-width: 992px) {
    #fullScreen #slider.open .col-12.open .pnlm-container {
        width: 100%!important;
        max-height: 100%;
        height: 100%;
    }
    .allHighResBtn {
        display: none!important;
    }
    h2.accordion-header {
        right: 0px;
    }
    #fullScreen .row.configurator-row {
        padding-top: 0px!important;
    }
    #fullScreen .row.configurator-row p {
        margin-bottom: 0px;
    }
    #slider.open .openSlider.slide.open .imgParent {
        height: 100%;
        display: flex!important;
        justify-content: center;
        align-items: center;
    }
    #maximizes {
        display: none!important;
    }
    #fullScreen.exterior-interior #projectName {
        top: -25px!important;
    }
    #fullScreen.exterior-interior h4 {
        top: 50px!important;
    }
    #projectNameTour h3.text-center {
        font-size: 18px;
        margin-bottom: 0px;
    }
    #projectNameTour h6 {
        font-size: 14px;
        margin-bottom: 0px;
    }
    .vjs-controls-enabled {
        max-width: 430px!important;
    }
    #projectName {
        top: 25px;
        font-size: 25px!important;
    }
    #interior {
        z-index: 10000;
        width: 100%;
    }
    #interior .nav__content {
        width: 100%;
    }
    /* configurator start */
    #configuratorExterior {
        z-index: 10000;
        width: 100%;
    }
    #configuratorExterior .nav__content {
        width: 100%;
    }
    /* configurator end */
    #exterior {
        z-index: 10000;
    }
    #exteriorNormalTour {
        z-index: 10000;
    }
    #interiorNormalTour {
        z-index: 10000;
    }
    #exterior .nav__content {
        width: 100%;
    }
    #exteriorNormalTour .nav__content {
        width: 100%;
    }
    #interiorNormalTour .nav__content {
        width: 100%;
    }
    #panoramsExterior {
        z-index: 10000;
    }
    #panoramsExterior .nav__content {
        width: 100%;
    }
    #panoramsInterior {
        z-index: 10000;
    }
    #panoramsInterior .nav__content {
        width: 100%;
    }
    .pnlm-container {
        height: 200px !important;
    }
    #fullScreen #slider.open .col-12.open .pnlm-container {
        height: 300px;
    }
    #menu-button {
        z-index: 11000000111;
    }
    #panoramsMenu {
        display: none!important;
    }
    /* Panorams Mini Menu start */
    #panoramsMenu ul li img.panoramsImg {
        max-height: 50px !important;
        width: auto;
    }
    #fullScreen .logoPano {
        right: 50px!important;
    }
    .full-screen-intro .logo {
        display: none;
    }
    #main-menu.animated {
        top: -5000px !important;
    }
    #menuPano {
        display: none!important;
    }
    #main-menu {
        transition: none !important;
        -webkit-transform: none !important;
                transform: none !important;
        top: -5000px !important;
    }
    #main-menu .logo2 {
        display: none !important;
    }
    #main-menu.minimized.animated {
        top: 100px !important;
    }
    .full-screen-text h4 {
        font-size: 18px;
    }
    .full-screen-text h1 {
        font: 700 30px "Roboto", "Open Sans", "Arial", sans-serif !important;
        padding-top: 10px;
    }
    .full-screen-text h5 {
        padding-top: 10px;
    }
    .full-screen-text #view {
        margin-top: 10px !important;
        padding: 15px 38px;
        background: transparent;
        border: 1px solid white;
        text-transform: uppercase;
        font-weight: bold;
    }
    .full-screen-text #view a {
        font-size: 13px;
        color: white;
        text-decoration: none;
    }
    .slider-control.slide-left {
        left: 5px!important;
    }
    .slider-control.slide-right {
        right: 5px!important;
    }
}

@media(max-width: 688px) {
    .nav__list-item:nth-child(1) {
        margin-top: 100px!important;
    }
    .nav__list-item {
        margin-top: 40px!important;
        margin-bottom: 40px!important;
    } 
}

@media (max-width: 768px) {
    .configurator-row h1 {
        top: 60px!important;
        font-size: 16px!important;
    }
    #fullScreen .row {
        padding-left: 0px!important;
        padding-right: 0px!important;
        padding-top: 80px!important;
    }
    .vjs-controls-enabled {
        max-width: 270px!important;
        height: 200px;
    }
    #fullScreen.exterior-interior #slider h5.videoTitle {
        max-width: 270px!important;
        width: 270px!important;
    }
    body.nav-active #interior .nav__content h2 {
        padding-top: 50px;
    }
    /* configurator start */
    body.nav-active8 #configuratorExterior .nav__content h2 {
        padding-top: 50px;
    }
    /* configurator end */
    body.nav-active2 #exterior .nav__content h2 {
        padding-top: 50px;
    }
    body.nav-active3 #virtualReality .nav__content h2 {
        padding-top: 50px;
    }
    body.nav-active4 #panoramsExterior .nav__content h2 {
        padding-top: 50px;
    }
    body.nav-active5 #panoramsInterior .nav__content h2 {
        padding-top: 50px;
    }
    body.nav-active6 #videosExterior .nav__content h2 {
        padding-top: 50px;
    }
    body.nav-active7 #videosInterior .nav__content h2 {
        padding-top: 50px;
    }    
    body.nav-active9 #exteriorNormalTour .nav__content h2 {
        padding-top: 50px;
    } 
    body.nav-active10 #interiorNormalTour .nav__content h2 {
        padding-top: 50px;
    }    
    /* Panorams Mini Menu start */
    #panoramsMenu ul li img.panoramsImg {
        max-height: 30px !important;
        width: auto;
    }
    .logo {
        display: none;
    }
    /* Panorams Mini Menu end */
}

@media(max-width: 576px) {
    #projectName {
        font-size: 18px!important;
        text-align: center;
        width: 100%;
        left: -30px;
    }
    #menu-button {
        right: 20px;
    }
    .slider-control {
        top: calc(50% - 24px)!important;
    }
    ul.nav__list {
        padding-bottom: 100px!important;
    }
    video {
        max-width: 270px!important;
    }
}

@media(max-width: 768px) {
    .allHighResBtn {
        margin-bottom: 90px;
        margin-right: 10px;
    }

    .highResBtn {
        margin-bottom: 90px!important;
        margin-right: 260px!important;
    }
}

#impressum {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 100;
    background: #F0F4F0;
}

#logo-impressum {
    max-height: 350px!important;
    width: 150px!important;
    max-width: 350px!important;
    position: absolute;
    left: 75%;
    top: 10px;
}

#footer {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    color: #000;
    z-index: 100000000;
    background: white;
    border-top: 1px solid transparent;
    /* border-top: 1px solid #24B04D; */
}

#footer a {
    /* color: #24B04D; */
    text-decoration: none;
}

#privacypolicy {
    overflow-y: scroll;
    min-height: 100vh;
    height: 100%;
}

#logo-privacy {
    max-height: 150px;
    right: 10px;
    float: right;
    top: 0;
}

.allHighResBtn {
    position: absolute;
    z-index: 1000000;
    top: 450px;
    right: 0;
    max-width: 170px;
    margin-bottom: 40px;
    margin-right: 9px;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    /* border: none; */
    border: 1px solid white;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
}

.highResBtn {
    position: absolute;
    z-index: 1000000;
    top: 330px;
    right: -440px;
    max-width: 170px;
    margin-bottom: 10px;
    margin-right: 450px;
    background: rgba(0,0,0,0.3);
    color: #fff;
    /* border: none; */
    border: 1px solid white;
    padding: 10px;
    font-weight: bold;
    font-size: 12px;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* resposive end  */

/* tours css  */


#fullScreen #slider.open .col-12.open .pnlm-container {
    width: 80%;
    height: 85%;
}

#body1 {
    width: 100%;
    height: 100%;
    --perspective: none;
    position: fixed;
    -webkit-perspective: var(--perspective);
            perspective: var(--perspective);
    overflow-x: visible;
    width: 100%;
    height: 100%;
}

#body.open #body1 {
    -webkit-perspective: 1500px;
            perspective: 1500px;
}

.rowi {
    height: 70px;
    left: 0;
    right: 0;
    margin: 0 auto!important;
    position: absolute;
    z-index: 10000000;
    flex-direction: row;
    bottom: -4px; 
    display: flex
}

.rowi ul li a p b {
    font-size: 10px;
}

#panoramaTitle .h6 {
    margin-bottom: 0px;
}

#panoramaTitle .h3 {
    margin-bottom: 0px;
}

button.vjs-big-play-button {
    top: 0!important;
    bottom: 0!important;
    left: 0!important;
    right: 0!important;
    position: absolute!important;
    margin: auto!important;
}

.accordion-button:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.3)!important;
    color: white!important;
}
.accordion-button{
    padding: 0!important;
}
video {
    width: 100%;
    max-width: 720px;
    max-height: 300px;
}
@charset "UTF-8";

/*!
 * Bootstrap v5.0.0-beta1 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*,
::after,
::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
     :root {
        scroll-behavior: smooth
    }
}

/* style tag from API start  */

.configurator-row {
    padding: 70px 110px 0!important;
}

.configurator-container {
    height: 100vh;
    position: relative;
}

.btn-group.btn-group-sm {
    position: absolute;
    top: 0;
    right: 20px!important;
    z-index: 200000!important;
}

#house-svg {
    position: absolute;
    z-index: 2;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    mix-blend-mode: multiply;
    width: 100%;
    z-index: 1;
    top: 0;
    left: 0
}

.mask-img {
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center center;
    -webkit-mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.house-pic {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.images {
    position: relative
}


/* style tag from API end  */

h2.accordion-header {
    position: absolute;
    right: 0px;
    z-index: 1000;
}
.accordion{
    min-height: 614px;
    overflow: hidden;
}
#headingRoof {
    top: 200px;
}

#headingGutters {
    top: 290px;
}

#headingWalls {
    top: 380px;
}

#headingWallsSecond {
    top: 470px;
}

#headingWindows {
    top: 560px;
}

#headingDoors {
    top: 650px;
}

.accordion-button {
    border: 0!important;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    /* color: #212529; */
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:focus,
.accordion-button:active,
.accordion-button:focus:active {
    -webkit-appearance: none!important;
    box-shadow: none!important;
    outline: none!important;
}

.accordion-button.collapsed {
    border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
    color: #fff;
    background-color: #212529;
    transition: background .3s ease;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(/static/media/download.76fa5ee0.svg);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    /* content: ""; */
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    /* border-color: #86b7fe; */
    outline: 0;
    /* box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25) */
}

.accordion-header {
    margin-bottom: 0
}
#headingDoors,#headingWindows,#headingWallsSecond,#headingWalls,#headingGutters,#headingRoof,.accordion-button{
    width: 100px;
    height: 80px;
    background: #fff;
    border-radius: 10px 0 0 10px!important;
}
.accordion-item:first-of-type .accordion-button {
    background: transparent!important;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem

}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 1px;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-width: 1px;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    
}

.accordion-collapse {
    border: solid rgba(0, 0, 0, .125);
    border-width: 0 1px;
    min-height: 614px;
    overflow: hidden;
}

.accordion-body {
    border: 0px!important;
    padding: 1rem 1.25rem;
}

.accordion-collapse {
    border: 0px !important;
}

.accordion-item {
    border: 0px!important;
}

.accordion-button::after {
    display: none;
}

.accordion-flush .accordion-button {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}


/* new color picker  */

.Polaris-Labelled__LabelWrapper {
    display: none;
}

.Polaris-Connected__Item.Polaris-Connected__Item--connection {
    display: none;
}

.Polaris-TextField__Input {
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 0;
    border-color: transparent;
    box-shadow: 0px 5px 10px rgba(0 0 0 / 50%);
}

.Polaris-TextField__Input:focus,
.Polaris-TextField__Input:active,
.Polaris-TextField__Input:focus:active {
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
}

@media screen and(min-width: 1500px) and (max-width: 1960) {
    h2.accordion-header {
        right: -30px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    h2.accordion-header {
        max-width: 100px;
    }
    #headingRoof {
        /* top: 200px; */
        top: 510px;
        left: 17%;
    }
    
    #headingGutters {
        /* top: 290px; */
        top: 510px;
        left: 27%;
    }
    
    #headingWalls {
        /* top: 380px; */
        top: 510px;
        left: 37%;
    }
    
    #headingWallsSecond {
        /* top: 470px; */
        top: 510px;
        left: 47%;
    }
    
    #headingWindows {
        /* top: 560px; */
        top: 510px;
        left: 57%;
    }
    
    #headingDoors {
        /* top: 650px; */
        top: 510px;
        left: 67%;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .configurator-container {
        margin-bottom: 250px;
    }
    h2.accordion-header {
        max-width: 100px;
    }
    #headingRoof {
        /* top: 200px; */
        top: 500px;
        left: 17%;
    }
    
    #headingGutters {
        /* top: 290px; */
        top: 500px;
        left: 27%;
    }
    
    #headingWalls {
        /* top: 380px; */
        top: 500px;
        left: 37%;
    }
    
    #headingWallsSecond {
        /* top: 470px; */
        top: 500px;
        left: 47%;
    }
    
    #headingWindows {
        /* top: 560px; */
        top: 500px;
        left: 57%;
    }
    
    #headingDoors {
        /* top: 650px; */
        top: 500px;
        left: 67%;
    }
}

@media(max-width: 575px) {
    .configurator-container {
        margin-bottom: 20px;
    }
    h2.accordion-header {
        
    }
    #headingRoof {
        top: 350px;
        left:  calc(5% + 0px);
    }
    #headingDoors, #headingWindows, #headingWallsSecond, #headingWalls, #headingGutters, #headingRoof, .accordion-button{
        width: 50px;
        height: 50px;
        border-radius: 10px!important;
        padding: 1px;
    }
    #headingGutters {
        top: 350px;
        left: calc(5% + 55px);
    }
    
    #headingWalls {
        top: 350px;
        left: calc(5% + 110px);
    }
    
    #headingWallsSecond {
        top: 350px;
        left: calc(5% + 165px);
    }
    
    #headingWindows {
        top: 350px;
        left: calc(5% + 220px);
    }
    
    #headingDoors {
        top: 350px;
        left: calc(5% + 275px);
    }
    .accordion-item h2 button img {
        max-height: 30px!important;
    }
    #fullScreen .row.row-without-top {
        padding-bottom: 10px!important;
    }
}
